import React from "react";
import DefaultLayout from "../components/DefaultLayout";
import Hero from "../components/bricks/Hero";
import {themeBright, themeDark} from "../page-styles/landingpage.css";
import ThemeBox from "../components/bricks/ThemeBox";
import ThemedMultiFeature from "../components/bricks/ThemedMultiFeature";
import {Box, XCol} from "../components/xui/Box";
import ThemedSingleFeature, {MiniQuote} from "../components/bricks/ThemedSingleFeature";
import {CTAContainer} from "../components/TryCodecks";
import {ThemedButton} from "../components/xui/ThemedButton";
import socialMediaImg from "../imgs/social-media-decky.png";

const Discord = ({location}) => (
  <DefaultLayout
    title="Community management for busy game developers"
    description="The best feedback bot for Discord"
    location={location}
    footer={null}
    img={socialMediaImg}
  >
    <Hero
      title="Community Management for Busy People"
      subline="Say hi to Decky - the best feedback bot for Discord"
      img="discord-hero.png"
      button={{
        label: "Get Started with Decky",
        hint: "Codecks is free for small teams.",
        to: "/create-account/",
      }}
      noNav
    />
    <ThemeBox theme={themeBright}>
      <ThemedMultiFeature
        features={[
          {
            title: "Energize your community",
            img: "discord-feedback.png",
            description:
              "Collect feature requests, ideas and bug reports directly from your Discord community.",
          },
          {
            title: "A playful approach",
            img: "discord-7.png",
            description:
              "Leaderboards and upvoting will rally your fans and let them know that you’re listening.",
          },
          {
            title: "Keep your fans in the loop",
            img: "discord-shoutout.png",
            description:
              "Decky will share your progress and inform fans how their feedback is being used in your work.",
          },
        ]}
      />

      <XCol maxWidth="md" ml="auto" mr="auto">
        <MiniQuote authorName="Kerri King" companyName="Freedom Games" img="kerri-king">
          Decky has been an amazing tool for helping us make sure that our players feel like their
          problems and suggestions are being heard by the game devs. It's also been wonderful in
          helping us on the game dev side of things keep all of those suggestions and reports
          ordered properly and work through them efficiently!
        </MiniQuote>
      </XCol>
    </ThemeBox>

    <ThemeBox theme={themeDark}>
      <ThemedSingleFeature childAsDescription title="A direct connection" img="discord-4.png">
        Decky will take care of tracking and messaging users and collect any follow-up details they
        have for you.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        title="Everything in its right place"
        img="discord-5.png"
        imgRight
      >
        All important data remains in one place, easy to find and share between different members of
        your team.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        title="Everything at a glance"
        img="discord-dashboard.png"
      >
        Use the dashboard to quickly find trending feedback and bug reports.
      </ThemedSingleFeature>

      <XCol maxWidth="md" ml="auto" mr="auto">
        <MiniQuote
          authorName="Felipe Falanghe"
          authorInfo="Kerbal Space Program"
          companyName="Floating Origin Interactive"
          img="felipe-falanghe"
        >
          Codecks is an order of magnitude above all project management solutions we've used before.
          I've used other integrated tools before, but this takes it to a whole new level.
        </MiniQuote>
      </XCol>
    </ThemeBox>

    <CTAContainer sp={6}>
      <Box as="h3" color="primary" size="xxl" bold textAlign="center">
        Collect feedback in a playful way.
      </Box>
      <XCol sp={4} align="center">
        <ThemedButton kind="primary" to="/create-account/">
          Create your Account
        </ThemedButton>
      </XCol>
    </CTAContainer>
  </DefaultLayout>
);

export default Discord;
